@import "../website-v2/_variables.less";

.copy-section {
	display: flex;
	flex-direction: column;
	gap: var(--space-32);
	margin: var(--space-0) auto var(--space-80);
	// TODO: Adjust that once global settings are changed
	max-width: 48.8125rem !important;
	width: 100% !important;
	align-items: start;

	// Component context styles
	.header:first-child {
		margin-bottom: var(--space-24);
	}

	.header:not(:first-child) {
		.header__heading {
			font-size: 1.75rem;
		}

		.header__subheading,
		.header__subheading p,
		.header__subheading ul {
			font-size: var(--h5);
		}
	}

	.header__content {
		gap: var(--space-24);
	}

	.header__subheading,
	.header__subheading p {
		color: var(--text-tertiary);
		font-size: 1.375rem;
		font-weight: 400;
		line-height: 160%;
		text-align: left;

		ul, ol {
			margin: 0;
		}
	}

	.paragraph {
		font-size: var(--h5);
		text-align: left;
	}
}